/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
body{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.bg-ap{
  background: linear-gradient(179.83deg,#19032a 59%,#301248 87.39%);
}

.p-card {
    box-shadow: none !important;
  }

  p-password {
    div, input {
        width: 100%;
    }
  }
